<template>
  <g>
    <svg:style>
      .cls-1,
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }

      .cls-1, .cls-2, .cls-3 {
        stroke-miterlimit: 10;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient);
      }

      .cls-9 {
        fill: url(#inox-gradient-2);
      }

      .cls-10 {
        fill: url(#inox-gradient-3);
      }

      .cls-11 {
        fill: url(#inox-gradient-4);
      }

      .cls-12 {
        fill: url(#inox-gradient-5);
      }

      .cls-13 {
        fill: url(#inox-gradient-6);
      }

      .cls-14 {
        fill: url(#inox-gradient-7);
      }

      .cls-15 {
        fill: url(#inox-gradient-8);
      }

      .cls-16 {
        fill: url(#inox-gradient-9);
      }
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.33"
                    :y1="doorTopHeight + 150.96"
                    :x2="doorLeftWidth + 17.01"
                    :y2="doorTopHeight + 150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth + 71.18"
                    :y1="doorTopHeight + 130.44"
                    :x2="doorLeftWidth + 71.18"
                    :y2="doorTopHeight + 117.73"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth + 71.18"
                    :y1="doorTopHeight + 77.39"
                    :x2="doorLeftWidth + 71.18"
                    :y2="doorTopHeight + 64.62"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth + 71.18"
                    :y1="doorTopHeight + 183.48"
                    :x2="doorLeftWidth + 71.18"
                    :y2="doorTopHeight + 170.78"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth + 71.18"
                    :y1="doorTopHeight + 236.6"
                    :x2="doorLeftWidth + 71.18"
                    :y2="doorTopHeight + 223.82"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 63.68"
                    :x2="doorLeftWidth + 119.82"
                    :y2="doorTopHeight + 63.68"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 78.33"
                    :x2="doorLeftWidth + 119.82"
                    :y2="doorTopHeight + 78.33"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 169.84"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorTopHeight + 169.84"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 184.42"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorTopHeight + 184.42"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-5"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 116.79"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorTopHeight + 116.79"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-6"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 131.37"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorTopHeight + 131.37"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-7"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 237.53"
                    :x2="doorLeftWidth + 119.82"
                    :y2="doorTopHeight + 237.53"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-8"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 43.46"
                    :y1="doorTopHeight + 222.88"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorTopHeight + 222.88"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-9"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 42.55"
                    :y1="doorTopHeight + 22.26"
                    :x2="doorLeftWidth + 42.55"
                    :y2="doorTopHeight + 280.03"
                    xlink:href="#handle-gradient"/>

    <g id="H10">
      <!-- <g v-if="showVariant === 'outside' && showInox"> -->
      <g v-if="showInox">
        <rect id="inox-2"
              data-name="inox"
              class="cls-9"
              :x="doorLeftWidth + 43.46"
              :y="doorTopHeight1 + 77.39"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-4"
              data-name="inox"
              class="cls-11"
              :x="doorLeftWidth + 43.46"
              :y="doorTopHeight1 + 183.48"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-6"
              data-name="inox"
              class="cls-13"
              :x="doorLeftWidth + 43.46"
              :y="doorTopHeight1 + 130.44"
              :width="inoxW"
              height="1.87"/>
        <rect id="inox-7"
              data-name="inox"
              class="cls-14"
              :x="doorLeftWidth + 43.46"
              :y="doorTopHeight1 + 236.6"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-9"
              data-name="inox"
              class="cls-16"
              :x="doorLeftWidth + 41.65"
              :y="inoxTop"
              width="1.81"
              :height="inoxH"/>
      </g>
      <!-- <g v-if="showVariant === 'inside' && showInox">
        <rect id="inox"
              class="cls-9"
              :x="doorLeftWidth + 39.01"
              :y="doorTopHeight1 + 113.3"
              width="63.63"
              height="21.21"/>

        <rect id="inox-2"
              data-name="inox"
              class="cls-11"
              :x="doorLeftWidth + 39.01"
              :y="doorTopHeight1 + 60.27"
              width="63.63"
              height="21.21"/>

        <rect id="inox-3"
              data-name="inox"
              class="cls-8"
              :x="doorLeftWidth + 39.01"
              :y="doorTopHeight1 + 166.32"
              width="63.63"
              height="21.21"/>

        <rect id="inox-4"
              data-name="inox"
              class="cls-10"
              :x="doorLeftWidth + 39.01"
              :y="doorTopHeight1 + 219.35"
              width="63.63"
              height="21.21"/>

      </g> -->
      <rect id="glass"
            class="cls-4"
            :x="doorLeftWidth + 43.58"
            :y="doorTopHeight1 + 117.73"
            width="55.2"
            height="12.71"/>
      <rect id="glass-2"
            data-name="glass"
            class="cls-5"
            :x="doorLeftWidth + 43.58"
            :y="doorTopHeight1 + 64.62"
            width="55.2"
            height="12.78"/>
      <rect id="glass-3"
            data-name="glass"
            class="cls-6"
            :x="doorLeftWidth + 43.58"
            :y="doorTopHeight1 + 170.78"
            width="55.2"
            height="12.71"/>
      <rect id="glass-4"
            data-name="glass"
            class="cls-7"
            :x="doorLeftWidth + 43.58"
            :y="doorTopHeight1 + 223.82"
            width="55.2"
            height="12.78"/>
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      leftOffset: 87,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 :
        this.doorHeight - this.leafOffset * 2
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - this.leftOffset / 2 :
        this.doorWidth - this.leafOffset - this.leftOffset / 2
    },
    inoxTop() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  }
}
</script>
